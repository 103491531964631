import React from 'react'
import { Navbar } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import classnames from 'classnames'

import { signOut } from '~/store/modules/auth/actions'
import NavbarUser from './NavbarUser'
import NavbarBookmarks from './NavbarBookmarks'
import styled from 'styled-components'
import { queries } from '~/constants/mediaQuery'
import Logo from '../../../assets/img/logo/locasty-logo.svg'

const ThemeNavbar = props => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.user?.profile)

  const handleSignOut = () => {
    dispatch(signOut())
    window.location.reload()
  }

  const colorsArr = ['primary', 'danger', 'success', 'info', 'warning', 'dark']
  const navbarTypes = ['floating', 'static', 'sticky', 'hidden']
  return (
    <>
      <div className="content-overlay" />
      <div className="header-navbar-shadow" />
      <Navbar
        className={classnames(
          'header-navbar navbar-expand-lg navbar navbar-with-menu navbar-shadow',
          {
            'navbar-light':
              props.navbarColor === 'default' ||
              !colorsArr.includes(props.navbarColor),
            'navbar-dark': colorsArr.includes(props.navbarColor),
            'bg-primary':
              props.navbarColor === 'primary' && props.navbarType !== 'static',
            'bg-danger':
              props.navbarColor === 'danger' && props.navbarType !== 'static',
            'bg-success':
              props.navbarColor === 'success' && props.navbarType !== 'static',
            'bg-info':
              props.navbarColor === 'info' && props.navbarType !== 'static',
            'bg-warning':
              props.navbarColor === 'warning' && props.navbarType !== 'static',
            'bg-dark':
              props.navbarColor === 'dark' && props.navbarType !== 'static',
            'd-none': props.navbarType === 'hidden' && !props.horizontal,
            'floating-nav':
              (props.navbarType === 'floating' && !props.horizontal) ||
              (!navbarTypes.includes(props.navbarType) && !props.horizontal),
            'navbar-static-top':
              props.navbarType === 'static' && !props.horizontal,
            'fixed-top': props.navbarType === 'sticky' || props.horizontal,
            scrolling: props.horizontal && props.scrolling,
          }
        )}
      >
        <div className="navbar-wrapper">
          <div className="navbar-container content">
            <div
              className="navbar-collapse d-flex justify-content-between align-items-center"
              id="navbar-mobile"
            >
              <StyledBookmark className="bookmark-wrapper">
                <NavbarBookmarks
                  sidebarVisibility={props.sidebarVisibility}
                  handleAppOverlay={props.handleAppOverlay}
                />
              </StyledBookmark>

              <StyledLogo>
                <a href="/">
                  <img width="30" height="30" src={Logo} alt="Logo Locasty" />
                </a>
              </StyledLogo>

              <NavbarUser
                handleAppOverlay={props.handleAppOverlay}
                changeCurrentLang={props.changeCurrentLang}
                userName={user.name ?? 'User'}
                userImg={user?.avatar}
                loggedInWith={user?.loggedInWith}
                logoutWithJWT={handleSignOut}
              />
            </div>
          </div>
        </div>
      </Navbar>
    </>
  )
}

const StyledBookmark = styled.div`
  @media ${queries.less.sm} {
    display: none;
  }
`

const StyledLogo = styled.div`
  @media ${queries.more.sm} {
    display: none;
  }
`

export default ThemeNavbar
